
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.create {
  display: grid;
  gap: 40px;
  justify-items: center;
  padding: 70px 18px;
  white-space: pre-line;
  background-color: $color-gray-50;

  @media (min-width: $breakpoint-tablet) {
    gap: 56px;
    padding: 70px 18px 80px;
  }

  .title {
    font-weight: 800;
    font-size: 26px;
    text-align: center;

    @include h3;

    @media (min-width: $breakpoint-tablet) {
      white-space: normal;

      @include h1;
    }
  }

  .card_wrapper {
    display: flex;
    flex-flow: column;
    gap: 16px;
    width: 100%;
    max-width: 400px;

    @media (min-width: $breakpoint-mobile) {
      flex-flow: row;
      max-width: 900px;
    }

    @media (min-width: $breakpoint-tablet) {
      gap: 20px;
      max-width: 1140px;
    }

    .card {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
      background: $color-white;
      border: 1px solid rgb(0 0 0 / 5%);
      border-radius: 20px;
      box-shadow: 0 2px 10px rgb(42 39 65 / 10%);

      @media (min-width: $breakpoint-tablet) {
        &:nth-child(1) {
          width: 96.5%;
        }
      }

      .text_wrapper {
        display: flex;
        flex-flow: column;
        gap: 30px;
        justify-content: flex-start;
        height: 100%;
        padding: 30px 40px 10px;

        @media (min-width: $breakpoint-mobile) {
          justify-content: space-between;
          padding: 30px 36px 10px;
        }

        @media (min-width: $breakpoint-tablet) {
          gap: unset;
          justify-content: space-between;
          padding: 40px 60px;
        }

        .text_items {
          display: grid;
          gap: 10px;

          .sub_title {
            display: inline-block;
            margin: 0;
            color: $color-gray-600;
            font-weight: 700;
            font-size: 20px;
            white-space: normal;

            @media (min-width: $breakpoint-mobile) {
              font-size: 22px;
            }

            @media (min-width: $breakpoint-tablet) {
              font-size: 30px;
              line-height: 140%;
              white-space: pre-line;
            }

            b {
              color: #6758ff;
              font-weight: 700;
            }
          }

          .card_description {
            margin: 0;
            color: $color-gray-500;
            font-weight: 600;
            font-size: 14px;
            white-space: normal;

            @media (min-width: $breakpoint-mobile) {
              white-space: pre-line;
            }

            @media (min-width: $breakpoint-tablet) {
              margin-bottom: 20px;
              font-size: 18px;
            }
          }
        }

        .link {
          display: flex;
          gap: 4px;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 0;
          color: $color-primary-default;
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          cursor: pointer;

          @media (min-width: $breakpoint-tablet) {
            padding: 15px 0;
            font-size: 18px;
          }
        }
      }

      .image_wrapper {
        width: 100%;

        @media (min-width: $breakpoint-tablet) {
          top: 50%;
        }

        &:nth-child(1) {
          width: 96.5%;
        }
      }

      .card_image {
        width: 100%;
        height: 100%;
        object-fit: scale-down;

        @media (min-width: $breakpoint-tablet) {
          width: 100%;
          height: 100%;
          height: auto;
        }
      }
    }
  }
}
