
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.partners {
  display: grid;
  justify-items: center;
  padding: 50px 0 0;
  white-space: pre-line;
  text-align: center;
  background: #edf0fa;

  @media (min-width: $breakpoint-tablet) {
    white-space: normal;
  }

  .title {
    margin: 0;
    color: $color-gray-700;
    font-weight: 800;
    font-size: 26px;
    line-height: 130%;

    @media (min-width: $breakpoint-tablet) {
      font-size: 34px;
    }
  }

  .sub_description {
    padding: 16px 20px 0;
    color: $color-gray-500;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;

    @media (min-width: $breakpoint-mobile) {
      font-size: 16px;
    }
  }

  .partners_button {
    margin: 36px 0;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 30px;

    @media (min-width: $breakpoint-tablet) {
      margin: 36px 0 14px;
      padding: 14px 20px;
      font-size: 18px;
    }
  }

  .partners_image {
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
