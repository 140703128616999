
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.overview_02 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 18px;
  background-color: $color-gray-50;

  @media (min-width: $breakpoint-tablet) {
    padding: 70px 18px;
  }

  .card_wrapper {
    display: flex;
    flex-flow: column;
    gap: 16px;

    @media (min-width: $breakpoint-mobile) {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: $breakpoint-tablet) {
      grid-template-columns: repeat(26, 1fr);
      gap: 30px;
      max-width: 1140px;
    }

    .text_card {
      display: flex;
      flex-flow: column;
      grid-column: 1;
      grid-column-end: 5;
      gap: 16px;
      align-items: center;
      width: 100%;
      max-width: 1140px;
      margin-bottom: 24px;
      text-align: center;

      @media (min-width: $breakpoint-mobile) {
        gap: 12px;
      }

      @media (min-width: $breakpoint-tablet) {
        grid-column-end: 10;
        align-items: flex-start;
        margin: 70px 0;
        margin: 0;
        padding: 40px 0 40px 20px;
        white-space: pre-line;
        text-align: left;
      }

      .question_mark {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        color: $color-primary-default;
        font-weight: 800;
        font-size: 30px;
        background-color: $color-primary-background-hover;
        border-radius: 27px;
      }

      .title {
        margin: 0;
        color: $color-gray-700;
        font-weight: 800;
        font-size: 26px;
        line-height: 130%;

        @include h3;

        @media (min-width: $breakpoint-tablet) {
          @include h1;
        }
      }

      .sub_description {
        color: $color-gray-500;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
      }
    }

    .card {
      display: flex;
      flex-flow: column;
      width: 100%;
      max-width: 400px;
      overflow: hidden;
      background: $color-white;
      border: 1px solid rgb(0 0 0 / 5%);
      border-radius: 20px;
      box-shadow: 0 2px 10px rgb(42 39 65 / 10%);

      @media (min-width: $breakpoint-mobile) {
        justify-content: space-between;
        max-width: unset;
      }

      &:nth-child(2) {
        grid-column: 1;
        grid-column-end: 5;

        @media (min-width: $breakpoint-tablet) {
          grid-column: 10;
          grid-column-end: 27;
        }
      }

      &:nth-child(3) {
        grid-column: 1;
        grid-column-end: 3;

        @media (min-width: $breakpoint-tablet) {
          grid-column-end: 15;
        }
      }

      &:nth-child(4) {
        grid-column: 3;
        grid-column-end: 5;

        @media (min-width: $breakpoint-tablet) {
          grid-column: 15;
          grid-column-end: 27;
        }
      }

      .text_wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        padding: 30px 26px 10px;
        white-space: pre-line;
        text-align: center;

        @media (min-width: $breakpoint-tablet) {
          align-items: flex-start;
          padding: 50px 60px 20px;
          white-space: normal;
          text-align: left;
        }

        h3 {
          margin: 0;
          color: $color-gray-600;
          font-weight: 700;
          font-size: 20px;
          line-height: 140%;

          @media (min-width: $breakpoint-tablet) {
            font-size: 28px;
          }
        }

        p {
          margin: 0;
          color: $color-gray-500;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;

          @media (min-width: $breakpoint-tablet) {
            min-height: 54px;
            font-size: 18px;
          }
        }
      }

      .image_wrapper {
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;

          @media (min-width: $breakpoint-tablet) {
            height: auto;
          }
        }
      }
    }
  }
}
