
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
@mixin single-banner {
  @media (width < 600px) {
    @content;
  }
}

.slider_wrapper {
  position: relative;
  min-height: 225px;

  @include single-banner {
    height: 262px;
  }

  .ready .slide {
    visibility: visible;
  }

  .slide {
    display: flex;
    height: 225px;
    border-radius: 8px;
    visibility: hidden;
    cursor: pointer;
    opacity: 0.3;
    transition: opacity 0.3s;

    &.visible {
      opacity: 1;
    }

    @include tablet {
      flex-direction: column-reverse;
      height: 262px;
    }

    .content_wrapper {
      position: relative;

      .gradient {
        position: absolute;
        top: 0;
        right: -61px;
        width: 62px;
        height: 100%;

        @include tablet {
          top: -39px;
          left: 0;
          width: 100%;
          height: 40px;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 220px;
      height: 100%;
      padding: 24px 12px 24px 24px;
      word-break: break-word;

      @include tablet {
        width: 100%;
        height: auto;
        padding: 10px 18px 22px;
      }

      .tags_wrapper {
        display: flex;
        gap: 4px;
      }

      .tag {
        display: flex;
        align-items: center;
        height: 26px;
        padding: 0 8px;
        font-weight: 500;
        font-size: 11px;
        background-color: rgba($color-white, 54%);
        border-radius: 9999px;
      }

      .title {
        display: -webkit-box;
        overflow: hidden;
        font-weight: 700;
        font-size: 18px;
        white-space: pre-line;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include tablet {
          font-size: 16px;
        }
      }

      .description {
        display: -webkit-box;
        margin-top: 8px;
        overflow: hidden;
        font-weight: 500;
        font-size: 12px;
        white-space: pre-line;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: keep-all;
        opacity: 0.8;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include tablet {
          margin-top: 4px;
          font-size: 11px;
        }
      }

      @at-root .slide[data-dark='true'] {
        color: $color-white;

        .tag {
          background-color: rgba($color-white, 12%) !important;
        }
      }
    }

    .image {
      flex: 1;
      background-position: center;
      background-size: cover;
    }

    .border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid rgba($color-gray-700, 12%);
      border-radius: 8px;
      pointer-events: none;
    }
  }
}

.controller {
  display: flex;
  gap: 6px;
  justify-content: center;
  max-width: 1140px;
  margin: 12px auto 0;

  @include single-banner {
    position: absolute;
    top: 8px;
    right: calc(max(36px, calc((100% - 340px) / 2)) + 8px);
    margin: 0;
  }

  &_button {
    padding: 2px;
    overflow: hidden;
    border: 1px solid $color-gray-200;
    border-radius: 9999px;
    cursor: pointer;

    @include single-banner {
      display: none;
    }

    &:hover {
      background-color: $color-gray-100;
    }
  }

  &_indicator {
    display: flex;
    justify-content: center;
    width: 54px;
    border: 1px solid $color-gray-200;
    border-radius: 9999px;

    @include single-banner {
      width: 46px;
      background-color: rgba($color-gray-700, 12%);
      border: none;
    }

    &_text {
      margin: auto;
      font-size: 13px;
      user-select: none;

      @include single-banner {
        margin: 5px 12px;
        color: $color-white;
        font-weight: 500;
        font-size: 11px;
        text-shadow: 0 0 2px rgb(0 0 0 / 24%);
      }
    }
  }
}

.skeleton {
  top: 0;
  width: 90%;
  max-width: 1140px;
  height: 225px;
  margin-left: max(5%, calc((100% - 1140px) / 2));
  border-radius: 8px;

  @include single-banner {
    width: min(340px, calc(100% - 64px));
    height: 262px;
    margin-left: max(36px, calc((100% - 340px) / 2));
  }
}
