
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.section {
  font-size: 16px;
  background-color: $color-gray-50;
}

.inner {
  padding: 50px 18px;

  @media (min-width: $breakpoint-mobile) {
    padding: 70px 18px;
  }

  .title {
    margin: 0;
    font-weight: 800;
    font-size: 26px;
    text-align: center;

    @include h3;

    @media (min-width: $breakpoint-tablet) {
      @include h1;
    }
  }

  .faq {
    max-width: 650px;
    margin: 40px auto;

    @include space-y(6px);

    @media (min-width: $breakpoint-tablet) {
      max-width: 900px;
      margin: 50px auto;

      @include space-y(12px);
    }
  }

  .button_area {
    text-align: center;

    .button {
      padding: 0 20px;
    }
  }
}

.accordion {
  padding: 17px 18px;
  color: $color-gray-600;
  font-size: 14px;
  background-color: $color-white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgb(42 39 65 / 4%);

  @media (min-width: $breakpoint-tablet) {
    padding: 22px 30px;
  }

  .button {
    display: flex;
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 0;
    color: inherit;
    text-align: left;
    outline: none;

    @include body-1('sb');

    @media (min-width: $breakpoint-mobile) {
      @include subtitle('sb');
    }

    @media (min-width: $breakpoint-tablet) {
      gap: 12px;
      align-items: center;

      @include h5;
    }

    .leftIcon {
      display: flex;
      flex: 0 0 24px;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      color: #6758ff;
      font-size: 14px;
      background: #e9e8ff;
      border-radius: 40px;

      @media (min-width: $breakpoint-tablet) {
        flex: 0 0 34px;
        width: 34px;
        height: 34px;
        font-size: 20px;
      }
    }

    .rightIcon {
      margin-left: auto;
      transform: rotate(180deg);

      &.open {
        transform: rotate(0);
      }

      @media (min-width: $breakpoint-tablet) {
        width: 34px;
        height: 34px;
      }
    }
  }

  .panel {
    margin-top: 12px;
    padding-left: 32px;

    @include body-2('m');

    white-space: pre-line;
    overflow-wrap: anywhere;

    @media (min-width: $breakpoint-tablet) {
      padding-left: 44px;

      @include subtitle('m');
    }
  }

  .close {
    display: none;
  }
}
