
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.overview_01 {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 50px 18px;
  background-color: $color-gray-50;

  @media (min-width: $breakpoint-tablet) {
    padding: 100px 18px 70px;
  }

  .question_mark {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin: 0 auto;
    margin-bottom: 16px;
    color: $color-primary-default;
    font-weight: 800;
    font-size: 30px;
    background-color: $color-primary-background-hover;
    border-radius: 27px;

    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 20px;
    }
  }

  .title {
    margin: 0;
    color: $color-gray-700;
    font-weight: 800;
    font-size: 26px;
    line-height: 130%;
    text-align: center;

    @media (min-width: $breakpoint-tablet) {
      font-size: 34px;
    }
  }

  .card_wrapper {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
    max-width: 400px;
    margin: 40px 0;

    @media (min-width: $breakpoint-mobile) {
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      max-width: 900px;
    }

    @media (min-width: $breakpoint-tablet) {
      gap: 20px;
      max-width: 1140px;
      margin: 70px 0;
    }

    .card {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
      background: $color-white;
      border: 1px solid rgb(0 0 0 / 5%);
      border-radius: 20px;
      box-shadow: 0 2px 10px rgb(42 39 65 / 10%);

      .text_wrapper {
        display: grid;
        gap: 10px;
        padding: 32px 26px 14px;

        @media (min-width: $breakpoint-mobile) {
          white-space: normal;
        }

        @media (min-width: $breakpoint-tablet) {
          gap: 16px;
          padding: 50px;
          white-space: pre-line;
        }

        h3 {
          margin: 0;
          color: $color-gray-600;
          font-weight: 700;
          font-size: 20px;
          line-height: 140%;

          @media (min-width: $breakpoint-tablet) {
            font-weight: 800;
            font-size: 28px;
          }
        }

        p {
          margin: 0;
          color: $color-gray-500;
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;

          @media (min-width: $breakpoint-mobile) {
            font-size: 16px;
          }

          @media (min-width: $breakpoint-tablet) {
            font-size: 18px;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .more_cases_button {
    padding: 12px 20px;
    font-size: 14px;

    @media (min-width: $breakpoint-tablet) {
      padding: 14px 20px;
      font-size: 18px;
    }
  }
}
