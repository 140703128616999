
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.premium {
  display: grid;
  justify-items: center;
  padding: 0 18px;
  white-space: pre-line;

  &.gray {
    background-color: $color-gray-50;
  }

  &.white {
    background-color: $color-white;
  }

  @include tablet {
    padding: 70px 20px;
    background-color: $color-gray-700;

    &.gray {
      background-color: $color-gray-700;
    }

    &.white {
      background-color: $color-gray-700;
    }
  }

  @include mobile {
    padding: 50px 20px;
    background-color: $color-gray-700;

    &.gray {
      background-color: $color-gray-700;
    }

    &.white {
      background-color: $color-gray-700;
    }
  }

  .premium_feature_box {
    display: grid;
    justify-items: center;
    width: 100vw;
    margin: 0 -18px;
    padding: 60px 81px 80px 70px;
    color: $color-white;
    background-color: $color-gray-700;

    @include tablet {
      width: 100%;
      margin: 0;
      padding: 0;
      border-radius: unset;
    }

    @include mobile {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .text_wrapper {
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;

      .vip_bedge {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        overflow: hidden;
        border-radius: 30px;

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $color-mint-light;
          opacity: 0.2;
          content: '';
        }
      }

      .title {
        display: grid;
        justify-items: center;
        margin: 0;
        margin-top: 12px;
        font-weight: 800;
        font-size: 26px;
        text-align: center;

        > strong {
          color: $color-mint-light;
        }

        @media (min-width: $breakpoint-tablet) {
          font-size: 34px;
        }
      }

      .sub_description {
        margin-top: 16px;
        color: $color-gray-400;
        font-weight: 600;
        font-size: 16px;
      }
    }

    .premium_feature {
      display: flex;
      gap: 16px;
      align-items: stretch;
      width: 100%;
      max-width: 900px;
      margin: 70px 0;

      @include tablet {
        margin: 40px 0;
      }

      @include mobile {
        flex-flow: column;
        gap: 12px;
        max-width: 400px;
      }

      .feature_card {
        width: 100%;
        padding: 30px;
        background: rgb(255 255 255 / 4%);
        border-radius: 10px;

        @include tablet {
          padding: 24px;
        }

        h3 {
          margin: 0 0 16px 3px;
          font-size: 18px;

          @include tablet {
            font-size: 16px;
          }
        }

        ul {
          all: unset;

          @include space-y(8px);

          li {
            display: flex;
            align-items: flex-start;
            color: $color-gray-400;
            list-style: none;

            @include space-x(8px);

            @include tablet {
              font-size: 14px;

              img {
                width: 22px;
              }
            }
          }
        }
      }
    }

    .premium_button {
      padding: 0 20px;
      color: $color-black;
      background-color: $color-mint-light;
      border-radius: 30px;
    }
  }
}
