
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.section {
  background-color: $color-white;
}

.inner {
  padding: 50px 18px;

  @media (min-width: $breakpoint-mobile) {
    padding: 70px 18px;
  }
}

.text_box {
  text-align: center;

  .title {
    font-weight: 800;
    font-size: 26px;
    white-space: pre-line;

    @media (min-width: $breakpoint-tablet) {
      white-space: normal;

      @include h1;
    }
  }

  .text {
    margin-top: 16px;
    color: $color-gray-500;
    font-weight: 600;
    font-size: 14px;

    @media (min-width: $breakpoint-mobile) {
      @include subtitle('sb');
    }
  }
}

.logos {
  margin: 40px -18px;

  @include space-y(26px);

  @media (min-width: $breakpoint-mobile) {
    margin: 60px -18px;

    @include space-y(34px);
  }
}

.button_area {
  text-align: center;

  .button {
    padding: 12px 20px;

    @media (min-width: $breakpoint-tablet) {
      padding: 14px 20px;
      font-size: 18px;
    }
  }
}

.logo_image {
  width: 76px;
  height: 46px;

  @media (min-width: $breakpoint-tablet) {
    width: 118px;
    height: 72px;
  }
}
