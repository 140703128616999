
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.recruit {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 50px 18px;
  white-space: pre-line;
  background-color: $color-gray-700;

  @media (min-width: $breakpoint-tablet) {
    padding: 76px 18px 72px;
  }

  .logos {
    display: flex;
    align-items: center;
    justify-content: center;

    @include space-x(8px);

    @media (min-width: $breakpoint-tablet) {
      @include space-x(30px);
    }

    img {
      width: 158px;
      min-width: 150px;
      max-width: 239px;
      height: 48px;
      object-fit: contain;

      @media (min-width: $breakpoint-tablet) {
        width: 100%;
        height: 72px;
      }
    }
  }

  .sub_text {
    display: flex;
    flex-flow: column;
    margin: 0;
    padding: 20px 0;
    color: $color-gray-500;
    font-weight: 600;
    font-size: 12px;
    white-space: pre-line;
    text-align: center;
    word-break: keep-all;

    @media (min-width: $breakpoint-tablet) {
      padding: 45px 0;
      font-size: 16px;
      white-space: normal;
    }
  }

  .recruit_button {
    padding: 12px 20px;

    @media (min-width: $breakpoint-tablet) {
      padding: 14px 20px;
    }
  }
}
