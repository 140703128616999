
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.intro_visual {
  position: relative;
  height: 600px;
  background-color: $color-white;

  @media (min-width: $breakpoint-tablet) {
    height: 840px;
  }

  .video {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: $color-white;
  }

  .background_shadow {
    position: absolute;
    width: 100%;
    height: 400px;
    background: linear-gradient(
      180deg,
      #fff 0%,
      rgb(255 255 255 / 88%) 37.17%,
      rgb(255 255 255 / 28%) 75.19%,
      rgb(255 255 255 / 0%) 100%
    );
  }

  .title_wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 0 18px;

    @include space-y(24px);

    @include tablet {
      height: 340px;
    }

    .intro_title {
      margin-top: 40px;
      font-weight: 800;
      font-size: 25px;
      text-align: center;
      background: linear-gradient(90deg, #6758ff 0%, #994aff 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-box-decoration-break: clone;
      -webkit-text-fill-color: transparent;

      @media (min-width: $breakpoint-mobile-sm) {
        font-size: 28px;
      }

      @media (min-width: $breakpoint-tablet) {
        margin-top: 60px;
        font-size: 34px;
      }
    }

    .intro_button {
      background: linear-gradient(90deg, #6758ff 0%, #994aff 100%);
      border-radius: 30px;
      box-shadow: 0 4px 24px 2px rgb(0 0 0 / 6%);
      transition: 0.3s;
    }
  }
}
