
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.apps {
  display: grid;
  justify-items: center;
  padding: 150px 18px;
  white-space: pre-line;
  background-color: $color-white;

  @include tablet {
    padding: 70px 18px;
  }

  @include mobile {
    padding: 50px 18px;
  }

  .apps_info {
    display: flex;
    gap: 30px;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    max-width: 1116px;

    @include tablet {
      flex-flow: column;
      gap: 42px;
      align-items: center;
    }

    .app_contents {
      display: flex;
      flex-flow: column;
      gap: 16px;
      width: 100%;
      max-width: 397px;
      padding: 12px 0;

      @include tablet {
        align-items: center;
        padding: 0;
        text-align: center;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .title {
        margin: 16px 0 0;
        color: $color-gray-700;
        font-weight: 800;
        font-size: 34px;
        line-height: 130%;

        @include tablet {
          font-size: 22px;
        }
      }

      .sub_description {
        margin-top: 16px;
        color: $color-gray-500;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
      }

      .app_store {
        display: grid;
        gap: 10px;
        margin-top: 26px;

        @include tablet {
          display: flex;
          margin-top: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 160px;
          height: 50px;
          padding: 5px 16px 7px 14px;
          background-color: $color-gray-700;
          border-radius: 8px;
          cursor: pointer;

          @include tablet {
            width: 140px;
            height: 43.75px;
          }

          img {
            max-width: 100%;
            object-fit: scale-down;
          }
        }
      }
    }

    .app_images {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 689px;
      height: auto;

      img {
        width: 100%;
        height: auto;

        @include mobile {
          max-width: 354px;
        }
      }
    }
  }
}
