
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.slide {
  width: auto !important;
  padding: 0 7px;
  overflow: visible !important;

  @media (min-width: $breakpoint-tablet) {
    padding: 0 14px;
  }
}

.img {
  max-width: none;
  user-select: none;
}
