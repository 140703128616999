
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.section {
  font-size: 16px;
  background-color: $color-gray-50;
}

.banners_wrapper {
  padding-top: 72px;
  padding-bottom: 50px;
}

.inner {
  padding: 50px 18px;

  @media (min-width: $breakpoint-mobile) {
    padding: 70px 18px;
  }

  .title {
    margin: 0;
    font-weight: 800;
    font-size: 26px;
    white-space: pre-line;
    text-align: center;

    @include h3;

    @media (min-width: $breakpoint-mobile-sm) {
      white-space: normal;
    }

    @media (min-width: $breakpoint-tablet) {
      @include h1;
    }
  }

  .category {
    display: flex;
    justify-content: center;
    margin: 24px -18px;
    padding: 0 18px;

    @media (min-width: $breakpoint-tablet) {
      margin: 32px -18px 0;
    }

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.keen-slider) {
      width: auto;
      max-width: 100%;
      overflow: visible;
    }

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.keen-slider__slide) {
      width: auto !important;
      padding: 0 2px;
      overflow: visible !important;
    }
  }

  .spaces {
    margin: 36px -18px 34px;
    padding: 0 18px;
    overflow: hidden;

    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.keen-slider) {
      overflow: visible;
    }

    @media (min-width: $breakpoint-tablet) {
      margin: 40px -18px;

      /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
      :global(.keen-slider) {
        overflow: hidden;
      }
    }

    .list {
      position: relative;
      display: flex;
      max-width: 1138px;
      margin: 0 auto;

      .btn {
        position: absolute;
        top: 50%;
        z-index: 1;
        display: none;
        width: 28px;
        height: 28px;
        color: $color-gray-500;
        background-color: $color-white;
        border: 1px solid $color-gray-200;
        border-radius: 9999px;
        transform: translateY(-50%);

        svg {
          margin: auto;
        }

        &:first-of-type {
          left: -40px;
          transform: translateY(50%) rotate(180deg);
          transform-origin: top center;
        }

        &:last-of-type {
          right: -40px;
        }

        &:disabled {
          opacity: 0.4;
        }

        @media (min-width: $breakpoint-laptop-sm) {
          display: block;
        }
      }
    }
  }

  .card {
    display: flex;
    flex-direction: column;

    @include space-y(8px);

    .img {
      overflow: hidden;
      background-color: #f5f5f5;
      border-radius: 4px;
      box-shadow: inset 0 0 0 1px rgba($color-black, 0.05);
      aspect-ratio: 165 / 105;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      color: $color-gray-700;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .experience_button {
    padding: 12px 20px;

    @media (min-width: $breakpoint-tablet) {
      padding: 14px 20px;
      font-size: 18px;
    }
  }
}

.button_area {
  margin-top: 40px;
  text-align: center;

  .button {
    padding: 12px 20px;

    @media (min-width: $breakpoint-tablet) {
      padding: 14px 20px;
      font-size: 18px;
    }
  }
}
