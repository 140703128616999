
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px 90px;
  text-align: center;
  background-color: $color-gray-50;

  @include tablet {
    padding-bottom: 70px;
  }

  @include mobile {
    padding-bottom: 60px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  justify-content: center;
  width: 900px;
  height: 100%;

  @include tablet {
    width: 650px;
  }

  @include mobile {
    width: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  white-space: pre-line;
  word-break: break-all;
}

.title {
  color: $color-gray-700;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%; /* 28px */

  @include mobile {
    font-size: 16px;
  }
}

.description {
  color: $color-gray-600;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%; /* 19.6px */

  @include mobile {
    font-size: 13px;
  }
}

.policy_link {
  color: $color-blue-default;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  padding: 15.25px 15px 14.75px;
  background: #fff;
  border-radius: 999px;
  box-shadow: 0 2px 10px 0 rgb(42 39 65 / 4%);

  @include mobile {
    width: 70px;
    height: 70px;
    padding: 13.25px 13px 12.75px;
  }
}
