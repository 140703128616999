
      @import "@zep/ui-tokens/scss/_variables.scss";
      @import "@zep/ui/styles/_mixins.scss";
      @import "@zep/ui/styles/_variables.scss";
      @import "@zep/ui/styles/_shadow.scss";
      @import "@zep/ui/styles/_typography.scss";
    
.traffic {
  display: grid;
  gap: 40px;
  justify-items: center;
  padding: 50px 18px;
  background-color: $color-white;

  @media (min-width: $breakpoint-mobile-sm) {
    padding-top: 60px;
  }

  @media (min-width: $breakpoint-mobile) {
    padding-top: 70px;
  }

  @media (min-width: $breakpoint-tablet) {
    gap: 70px;
    padding: 100px 0;
  }

  .text_wrapper {
    display: grid;
    gap: 16px;
    justify-items: center;

    .users_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background: $color-primary-background-hover;
      border-radius: 27px;

      @media (min-width: $breakpoint-mobile) {
        display: none;
      }
    }

    .title {
      margin: 0;
      font-weight: 800;
      font-size: 26px;
      white-space: pre-line;
      text-align: center;

      @media (min-width: $breakpoint-mobile) {
        white-space: normal;
      }

      @media (min-width: $breakpoint-tablet) {
        font-size: 34px;
      }
    }
  }

  .traffic_columns {
    display: flex;
    flex-flow: column;
    gap: 38px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 964px;

    @media (min-width: $breakpoint-mobile) {
      flex-flow: row;
      gap: 10px;
    }

    @media (min-width: $breakpoint-tablet) {
      gap: 61px;
      max-width: 1025px;
    }

    .traffic_description {
      display: grid;
      gap: 4px;
      justify-items: center;
      width: 100%;
      max-width: 301px;

      @media (min-width: $breakpoint-tablet) {
        gap: 7px;
      }

      p {
        margin: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 130%;
        opacity: 0.6;

        @media (min-width: $breakpoint-tablet) {
          font-size: 18px;
        }
      }
    }

    &_text {
      font-weight: 700;
      font-size: 30px;
    }

    .counting {
      font-weight: 800;
      font-size: 54px;
      line-height: 140%;

      @media (min-width: $breakpoint-mobile) {
        font-size: 52px;
      }

      @media (min-width: $breakpoint-tablet) {
        font-size: 76px;
      }
    }
  }
}
